import { element } from "prop-types"
import React from "react"
import ScrollTracker from "./scroll-tracker"
import ShareBanner from "./share-banner"

class PostSidebar extends React.Component {
    sidebarStyle = {
        paddingTop: 100,
        marginLeft: -30
    }
    static defaultProps = {
        shareUrl: "https://ardit.dev"
    }
    returnLinks(links){
        console.log(window.location.pathname, "bruh")
        let linksF = [];
        links.forEach((element) => {
            let link = (typeof window !== 'undefined' ? window.location.origin + window.location.pathname : '')  +  "#" + element.replaceAll(" ", "-").toLowerCase()
            linksF.push(<a href={link}>• {element}</a>)
        })
        return linksF;
    }

    render() {
        return(
            <ScrollTracker>
                <div style={this.sidebarStyle}>
                    {((typeof window !== 'undefined' ? window.location.pathname : '') == "/posts/2022-02-18-exploring-machine-learning") &&
                        <>
                        <h4><strong>Overview:</strong></h4>
                        <div className="flex">
                            {this.returnLinks(["Starting Point", "The Idea", "Dataset", "The model", "Preparing data", "Testing", "Training", "Anvil", "Results"])}
                        </div>
                        </>
                    }
                    {((typeof window !== 'undefined' ? window.location.pathname : '') == "/posts/2022-04-18-worlds-first-albanian-programming-language") &&
                        <>
                        <h4><strong>Overview:</strong></h4>
                        <div className="flex">
                            {this.returnLinks(["Starting Point", "Setup", "Documentation"])}
                        </div>
                        </>
                    }
                    <p>Written by <br /><strong>Ardit Xhaferi</strong></p>
                    <p>Share this post!</p>
                    <ShareBanner shareUrl={this.props.shareUrl} />
                    <script data-name="BMC-Widget" data-cfasync="false" src="https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js" data-id="ardit.dev" data-description="Support me on Buy me a coffee!" data-message="" data-color="#BD5FFF" data-position="Right" data-x_margin="18" data-y_margin="18"></script>

                </div>
            </ScrollTracker>
        )
    }
}

export default PostSidebar