import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Footer from "../components/footer"
import NavigationSpacer from "../components/navigation-spacer"
import PostSidebar from "../components/post-sidebar"
import Seo from "../components/seo"
import ShareBanner from "../components/share-banner"


function returnLinks(links){
    let linksF = [];
    links.forEach((element) => {
        let link = (typeof window !== 'undefined' ? window.location.origin + window.location.pathname : '')  +  "#" + element.replaceAll(" ", "-").toLowerCase()
        linksF.push(<a href={link}>{element}</a>)
    })
    return linksF;
}

console.log((typeof window !== 'undefined' ? window.location.pathname : ''));

if(typeof window !== 'undefined'){
    window.onscroll = function() {myFunction()};
}

function myFunction() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 100;
  document.getElementById("myBar").style.width = scrolled + "%";
}


const PostTemplate = ({ data, location }) => {
    const post = data.mdx
    const { previous, next } = data
    const postUrl = data.site.siteMetadata.siteUrl + post.fields.slug

    useState(() => {
        setTimeout(copy, 1000)
        function copy(){
            if(postUrl == "https://ardit.dev/posts/2022-01-18-debug-safely"){
                document.getElementById('test').addEventListener(
                    'copy', function(e){
                        e.clipboardData.setData('text/plain', 
                            'nope actually not dumbass'
                        );
                    e.preventDefault();
                })
            }
        }
        
    })

    return (
        <div className="main-page h-100">
            <Seo
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt}
                image={post.frontmatter.image}
            />
            <section id="top" className="container-fluid d-flex flex-column main-mh-100">
                <NavigationSpacer />
                <div className="container flex-grow-1">
                    <div className="row justify-content-center">
                        <div className="d-none d-xl-block col-xl-2">
                            <PostSidebar shareUrl={postUrl} />
                        </div>
                        <div className="col-md-10 col-xl-8 mt-4">
                            <p className="m-0 main-fade-in-animation">
                                <a href="/posts"><strong>← ../</strong></a>
                            </p>
                            {((typeof window !== 'undefined' ? window.location.pathname : '') == "/posts/2022-02-18-exploring-machine-learning") &&
                                <>
                                    <div className="mt-4 anchor-mobile d-xl-none ">
                                        {returnLinks(["Starting Point", "The Idea", "Creating the dataset", "The model", "Preparing data", "Testing", "Training", "Anvil", "Results"])}
                                    </div>
                                    <div class="progress-container d-xl-none">
                                        <div class="progress-bar" id="myBar"></div>
                                    </div>
                                </>
                            }

                            <article id="post-content" itemScope itemType="http://schema.org/Article">
                                <header>
                                    <h1 itemProp="headline">{post.frontmatter.title}</h1>
                                    <p className="main-reveal-text-short d-inline-block">{post.frontmatter.date} &#8226; {post.timeToRead + 3} min read</p>
                                </header>
                                <section
                                    itemProp="articleBody"
                                    className="main-fade-in-animation"
                                >
                                    <MDXRenderer>{post.body}</MDXRenderer>
                                </section>
                                <hr />
                            </article>
                        </div>
                        <div className="d-none d-xl-block col-xl-2"></div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-xl-8">
                            <p>Published {post.frontmatter.date}, by <strong>Ardit Xhaferi</strong>.</p>
                            <p>You don't have to share this post, but if you feel like it:</p>
                            <ShareBanner shareUrl={postUrl} />
                            <hr />
                        </div>
                    </div>
                    <nav className="mb-5">
                        <div className="row justify-content-center">
                            <div className="col-6 col-md-5 col-xl-4 text-start">
                                {next && (
                                    <React.Fragment>
                                        <p className="m-0">← Newer</p>
                                        <Link to={next.fields.slug} rel="next">
                                            {next.frontmatter.title}
                                        </Link>
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="col-6 col-md-5 col-xl-4 text-end">
                                {previous && (
                                    <React.Fragment>
                                        <p className="m-0">Older →</p>
                                        <Link to={previous.fields.slug} rel="prev">
                                            {previous.frontmatter.title}
                                        </Link>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </nav>
                </div>
                <Footer />
            </section>
        </div>
    )
}

export default PostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug(
        $id: String!
        $previousPostId: String
        $nextPostId: String
    ) {
        site {
            siteMetadata {
                title
                siteUrl
            }
        }
        mdx(id: { eq: $id }) {
            id
            timeToRead
            excerpt(pruneLength: 160)
            fields {
                slug
            }
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
                image
            }
            body
        }
        previous: mdx(id: { eq: $previousPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
        next: mdx(id: { eq: $nextPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
    }
`